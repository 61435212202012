html {
    scroll-behavior: smooth;
}

.screen-max-w {
    width: 1250px;
    max-width: 100%;
    margin-left: auto;
    margin-right: auto;
}

.navbar img.active+._nav-items {
    display: block !important;
}

._help-icon {
    color: rgb(156 163 175/0.8);
    font-size: 1rem;
    line-height: 1rem;
    cursor: pointer;
    margin-top: -0.15rem;
    transition: 0.3s;
}

._help-icon:hover {
    color: rgb(156 163 175);
    scale: 1.3;
}




.submit__btn {
    border-radius: 99999px;
    background-color: hsl(351deg 91% 48%);
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    padding: 10px 0;
    transition: .3s;
}

.submit__btn:hover {
    background-color: hsl(351deg 91% 39%);
}


.admin__order__details div {
    display: flex;
    justify-content: space-between;
    gap: 20px;
}

.admin__order__details div p:last-child {
    font-weight: 500;
}



/* Admin online status */
.admin__status {
    cursor: pointer;
    position: fixed;
    left: -65px;
    bottom: 50%;
    z-index: 9;
    transition: 0.4s;
}

.admin__status>div {
    box-shadow: 0 0 6px 1px #afafaf;
}

.indicator__>div {
    position: relative;
}

.indicator__>div>div {
    position: absolute;
    top: -3px;
    left: -3px;
    width: 15px;
    height: 15px;
    opacity: 0.7;
    background-color: inherit;
    border-radius: 50%;
    animation: blink__ 1.5s ease-out infinite;
}

/*Animations*/
@keyframes blink__ {
    100% {
        transform: scale(2);
        opacity: 0;
    }
}













.navlink__item {
    transition-duration: 300ms;
    --tw-text-opacity: 1;
    color: rgb(55 65 81 / var(--tw-text-opacity));
    padding-left: 1rem;
    padding-right: 1rem;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    --tw-bg-opacity: 1;
    background-color: rgb(243 244 246 / var(--tw-bg-opacity));
    border-radius: 0.25rem;
    gap: 0.5rem;
    align-items: center;
    display: flex;
}

.navlink__item.active {
    background-color: rgb(219 234 254);
    color: rgb(37 99 235);
}

@media screen and (max-width: 768px) {
    .sidemenu {
        position: fixed;
        width: 100%;
        height: calc(91vh + 2px);
        top: -101vh;
    }

    .sidemenu.active {
        top: calc(8% + 7px);
        z-index: 9;
    }
}

.side-menu a:hover, .side-menu button:hover {
    background-color: rgb(219 234 254);
    color: rgb(37 99 235);
}

.header {
    position: fixed;
    width: 100vw;
    padding: 0 2%;
    background-color: #ffffff00;
    z-index: 9;
    backdrop-filter: blur(5px);
}

.buy-sell img {
    object-fit: cover;
    user-select: none;
    -webkit-user-drag: none;
}

.main-logo:hover {
    background-color: #00000000;
}

.buy-sell>div {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 100%;
    transform: translate(-50%, -50%);
}




.products .product img {
    width: 100%;
    transition: .2s;
    position: relative;
    top: 0;
}

.rb-0 {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
}

.rt-0 {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
}

.products .product {
    cursor: pointer;
    border: 1px solid #00000010;
}

.products .product:hover img {
    top: -5px;
}















.spinner {
    z-index: 9999999;
}

.spinner svg {
    width: 100%;
    margin-top: -100px;
}

hr.login-hr {
    height: 1px;
    display: inline-block;
    width: 100px;
    margin: 5px;
}

.continue-with-button {
    border: 1px solid rgba(0, 0, 0, .14);
    background-color: white;
    padding: 10px 20px;
    margin: 2px 0;
    border-radius: 7px;
    transition: 0.1s ease-in-out;
}

.continue-with-button p {
    margin: 0 0 0 10px;
}

.continue-with-button img {
    height: 25px;
}











/* HOME PAGE */
.home {
    background-color: #b50b3b;
    background: linear-gradient(249.73deg, #D6311A 1.02%, #BE0639 17.13%, #4D003C 67.46%, #4D0037 98.51%);
    max-width: 100%;
}

.ps img {
    width: 30px;
}


.pms {
    background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.05) 100%), #FFFFFF;
    border: 1px solid #FFFFFF;
    box-sizing: border-box;
    box-shadow: 0px 10px 10px rgb(0 0 0 / 15%);
    border-radius: 8px;
}






/* why hover effects */
.why-div {
    box-shadow: 0 10px 15px -3px rgb(115 189 142 / 0.5), 0 4px 6px -4px rgb(115 189 142 / 1) !important;
    transition: 0.5s;
}

.why-div:hover {
    background-color: #4ade80;
    transform: scale(1.1);
}

.why-div:hover .why-icon, .why-div:hover p {
    color: white;
}


.why-us {
    background-image: url("./../../public/src/images/wave.svg");
}



/* orders-table */
.orders-table thead tr th {
    padding: 15px 0;
    text-align: left;
    background-color: white;
    transition: 0.3s;
}

.orders-table thead tr th:first-child {
    padding-left: 20px;
    border-top-left-radius: 17px;
}

.orders-table thead tr th:last-child {
    padding-right: 20px;
    border-top-right-radius: 17px;
}

.orders-table tbody tr td {
    padding: 8px 0;
    transition: 0.3s;
}

.orders-table tbody tr td:first-child {
    padding-left: 20px;
    width: 200px;
}

.orders-table tbody tr td:last-child {
    padding-right: 20px;
}

.orders-table tbody tr:nth-child(2n+1) {
    background-color: #ffffff30;
    transition: 0.3s;
}

/* .orders-table thead tr th:first-child {
    padding: 15px 30px;
    text-align: left;
}
.orders-table tbody tr td :first-child {
    padding: 0 0 0 30px; */
/* text-align: left; */
/* }

.orders-table tbody tr:last-child td {
    padding-bottom: 20px;
} */

















/* PMs */
.my-pms-box {
    width: 90px;
    height: 35px;
    outline: none;
    transition-duration: 300ms;
    outline-width: 0px;
    padding: 0.5rem;
    border: 2px solid #d1d5db;
    border-radius: 0.25rem;
}

.my-pms-box:disabled {
    color: #00000040;
    border: 2px solid #00000008;
}




.my-help-icon {
    opacity: 0.5;
    font-size: 1.125rem;
    line-height: 1.75rem;
    cursor: pointer;
    display: inline;
    margin-left: 0.25rem;
}



.topup-box {
    outline-width: 0px;
    margin-top: 0.25rem;
    border-width: 2px;
    transition-duration: 300ms;
    padding: 0.5rem 1rem;
    border-radius: 0.25rem;
    border-color: rgb(209 213 219);
}