@import url('https://fonts.googleapis.com/css2?family=Beau+Rivage&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,700;1,900&family=Tapestry&display=swap');
/*
font-family: 'Beau Rivage', cursive;
font-family: 'Roboto', sans-serif;
font-family: 'Tapestry', cursive;
*/

:root {
    --bg-gray: #f8faf9;
    --bg-red: #ea0b2c;
    --shadow-1: 0 1px 3px rgb(0 0 0/6%), 0 1px 2px rgb(0 0 0/10%);
    --shadow-2: 0 3px 6px rgb(0 0 0/15%), 0 2px 4px rgb(0 0 0/12%);
}

* {
    font-family: 'Roboto', sans-serif;
}

.register-box {
    box-shadow: var(--shadow-1);
}

.product-img {
    box-shadow: var(--shadow-2);
}



/* hide phone number box arrows */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

input[type=number] {
    -moz-appearance: textfield;
}